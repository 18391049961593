import React from "react";
import "./FooterStyle.scss";

import Logo from "./../../assets/images/logo-footer.png";

const Footer = () => {
  return (
    <footer>
      <div className="gap"></div>
      
      <div className="footer-bottom">
        <img className="logo-footer" src={Logo} alt="logo footer" />
        <nav>
          <ul>
            <li>
              {" "}
              <a
                href="https://www.cnpseguradora.com.br/"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                A CNP Seguradora {" "}
              </a>
            </li>
            <li>
              <a 
                href="https://www.cnpseguradora.com.br/politica-de-privacidade"
                target="_blank" 
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-end">
        <hr />
        <table className="footer-table">
          <td>
            <p>CNP Seguradora <br />
              Edifício Sede: Berrini One - Av. Engenheiro Luís Carlos Berrini, nº 105, 7º andar <br />
              Cidade Monções, São Paulo/SP - CEP: 04571-900
            </p>
          </td>
          <td className="copyright">
          </td>
        </table>
      </div>
      <div className="footer-end">
        <hr />
        <table className="footer-table">
          <td>
            <p>A CNP Seguradora reúne empresas de Seguros (CNPJ 92.751.213/0001-73), Consórcios (CNPJ 05.349.595/0001-09), Odonto (CNPJ 40.223.893/0001-59) e Capitalização (CNPJ 01.599.296/0001-71).</p> <br />
            <p>
              &copy; Copyright 2024 CNP Seguradora
            </p>
          </td>
        </table>
      </div>
    </footer>
  );
};

export default Footer;
