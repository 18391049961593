import styled from 'styled-components'

export const ImageContainer = styled.div`
  position: relative;
`

export const MiniImagesContainer = styled.div`
  display: flex;  
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 8px;
  left: 8px;
`