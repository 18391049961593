import React from "react";
import LogoHeader from "../../assets/images/logo-header.png";
import "./HeaderStyle.scss";
const Header = () => {
  return (
    <header>
      <img src={LogoHeader} alt="logo" className="DesktopImg" />
      <img src={LogoHeader} alt="logo" className="MobileImg" />
      <div className="LoginContainer">
        <span className="AccessAccountLink">Acesse sua conta</span>
        <button
          className="Button"
          onClick={() => window.open(process.env.REACT_APP_LOGIN_URL)}
        >
          Entrar
        </button>
      </div>
    </header>
  );
};
export default Header;
