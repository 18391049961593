import Footer from './components/footer/Footer.js';
import Header from './components/header/Header.js';
import Main from './components/main/index.js';
import ViParceiros from './components/Vi+Parceiros/ViParceiros';
function App() {
  return (
    <>
      <Header />
      <Main />
      <ViParceiros />
      <Footer />
    </>
  );
}

export default App;
