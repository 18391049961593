import React from "react";
import "./Banner.scss";

const Banner = ({ background, children, alt, className }) => {
  return (
    <div className={`Banner ${className}`}>
      <div className="Overlay">{children}</div>
      <img className="Background" src={background} alt={alt || "banner"} />
    </div>
  );
};

export default Banner;
