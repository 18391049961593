import React from 'react'

import * as S from './styles'

import './HomeOverlay.scss'
import logoBanner from '../../assets/images/logo-banner.png'

import ifoodLogo from '../../assets/images/ifood-logo.png'
import uberLogo from '../../assets/images/uber-logo.png'
import spotifyLogo from '../../assets/images/spotify-logo.png'
import boxLogo from '../../assets/images/box-logo.png'
import rennerLogo from '../../assets/images/renner-logo.png'

const HomeOverlay = () => {
  return (
    <S.HomeOverlayWrapper>
      <S.HomerOverlayContentContainer>
        <p className="title">
          Seu esforço <br />
          de venda + reconhecido é{' '}
        </p>
        <img src={logoBanner} alt="Logo Escrito Você + Valor" />
        <p className="description_mobile">
          Você+Premiado transforma suas vendas em pontos para trocar por produtos e vouchers.
        </p>
        <p className="description_desktop">
          Com o novo programa de pontos da CNP Seguradora, Você+Premiado, você
          transforma suas vendas em pontos para trocar por produtos e vouchers.{' '}
          <br />
          Venda os produtos do Você + Seguro, cadastre-se agora mesmo e comece a
          pontuar!
        </p>

        <div className="logos__wrapper">
          <img src={ifoodLogo} alt="Ifood Logo" />
          <img src={uberLogo} alt="Uber Logo" />
          <img src={spotifyLogo} alt="Spotify Logo" />
          <img src={boxLogo} alt="Box Logo" />
          <img src={rennerLogo} alt="Renner Logo" />
        </div>

        <button onClick={() => window.open(process.env.REACT_APP_LOGIN_URL)}>
          Clique aqui e cadastre-se!
        </button>
      </S.HomerOverlayContentContainer>
    </S.HomeOverlayWrapper>
  )
}

export default HomeOverlay
