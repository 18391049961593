import React from "react";
import Banner from "../banner/Banner";
import HomeBanner from "../../assets/images/Banner-Image.png";
import HomeBannerMobile from "../../assets/images/Banner-Mobile.png";
import "./index.scss";
import "../../styles/shared.scss";
import HomeOverlay from "../homeOverlay/HomeOverlay";
import Rewards from "../rewards/Rewards";

const Main = () => {
  return (
    <main>
      <Banner background={HomeBanner} className="DesktopOnly">
        <HomeOverlay />
      </Banner>
      <Banner background={HomeBannerMobile} className="MobileOnly">
        <HomeOverlay />
      </Banner>
      <Rewards />
    </main>
  );
};

export default Main;
