import React from "react";

import "./Rewards.scss";
import ViValor from "../../assets/images/banner-center.png";
import ifoodLogo from '../../assets/images/ifood-logo.png'
import uberLogo from '../../assets/images/uber-logo.png'
import spotifyLogo from '../../assets/images/spotify-logo.png'
import boxLogo from '../../assets/images/box-logo.png'
import rennerLogo from '../../assets/images/renner-logo.png'
import Logo from '../../assets/images/logo-banner.png'

import * as S from './styled'

const Rewards = () => {
  return (
    <div className="Container">
      <div className="TitleContainer">
        <S.ImageContainer className="DesktopOnly">
          <img src={ViValor} alt="Agora eu vi + valor" className="DesktopOnly" width={384} height={256}  />
          <S.MiniImagesContainer>
            <img src={ifoodLogo} alt="Ifood Logo" width={45} />
            <img src={uberLogo} alt="Uber Logo" width={45} />
            <img src={spotifyLogo} alt="Spotify Logo" width={45} />
            <img src={boxLogo} alt="Box Logo" width={45} />
            <img src={rennerLogo} alt="Renner Logo" width={45} />
          </S.MiniImagesContainer>
        </S.ImageContainer>
        
        <div className="DesktopOnly rewards__details">
          <p>Agora, seu esforço em vendas é + reconhecido</p>
          <p>É Você + Premiado!</p>
        </div>
      </div>
      <div className="RewardsList">
        <div className="RewardItem">
          <p className="RewardItemTitle">+ Variedade</p>
          <p className="RewardItemBody">
            São várias lojas participantes e muito mais produtos e serviços para
            você.
          </p>
        </div>
        <div className="RewardItem">
          <p className="RewardItemTitle">+ Escolha</p>
          <p className="RewardItemBody">
            Escolha como quer usar seus pontos: lanches, produtos,
            entretenimento ou vouchers.
          </p>
        </div>
        <div className="RewardItem">
          <p className="RewardItemTitle">+ Controle</p>
          <p className="RewardItemBody">
            Acompanhe sua pontuação, seus resgates e os cupons de sorteios
          </p>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
