import React from "react";
import "./ViParceiros.scss";

import MagaluLogo from "./../../assets/svgs/magalu.svg";
import Uber from "./../../assets/svgs/uber.svg";
import Renner from "./../../assets/svgs/renner.svg";
import McDonalds from "./../../assets/svgs/mcdonalds.svg";
import Cinemark from "./../../assets/svgs/cinemark.svg";
import Netshoes from "./../../assets/svgs/netshoes.svg";
import PlaystationStore from "./../../assets/svgs/playstationstore.svg";
import XboxLive from "./../../assets/svgs/xboxlive.svg";

const ViParceiros = () => {
  return (
    <section className="partners">
      <h1>Catálogo + Premiado</h1>
      <div className="container">
        <img className="logoPartner" src={MagaluLogo} alt="logo Magalu" /> 
        <img className="logoPartner" src={Uber} alt="logo uber" />
        <img className="logoPartner" src={PlaystationStore} alt="logo playstation store" />
        <img className="logoPartner" src={McDonalds} alt="logo McDonalds" />
        <img className="logoPartner" src={Cinemark} alt="logo cinemark" />
        <img className="logoPartner" src={XboxLive} alt="logo xbox_live" />
        <img className="logoPartner" src={Netshoes} alt="logo netshoes" />
        <img className="logoPartner" src={Renner} alt="logo Renner" />
      </div>
    </section>
  );
};

export default ViParceiros;
