import styled from 'styled-components'

export const HomeOverlayWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const HomerOverlayContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  line-height: 1.1;

  font-size: 18px;
  font-weight: 700;
  color: rgb(31, 44, 95);
  text-align: center;

  .title {
    margin-top: 24px;
  }

  @media (max-width: 320px) {
    margin: 20px 8px;
    right: 0;

    .title {
      margin-top: 0px;
    }
  }

  @media (min-width: 321px) {
    margin: 20px 8px;
    right: 0;
    font-size: 22px;

    .title {
      margin-top: 16px;
    }
  }

  @media (min-width: 425px) {
    margin: 20px 8px;
    right: 0;
    font-size: 22px;

    .title {
      margin-top: 24px;
    }
  }

  @media (min-width: 426px) and (max-width: 768px) {
    margin: 20px 8px;
    right: 0;
    font-size: 32px;

    .title {
      margin-top: 104px;
    }
  }

  @media (min-width: 920px) {
    font-size: 20px;
    font-size: 32px;
  }

  @media (min-width: 1096px) {
    margin: 40px 16px;
    font-size: 40px;
  }

  img {
    @media (max-width: 820px) {
      width: 300px;
      margin-top: -10px;
    }

    @media (min-width: 821px) {
      width: 400px;
    }

    @media (min-width: 920px) {
      width: 500px;
      margin-top: -20px;
    }

    @media (min-width: 1096px) {
      width: 600px;
      margin-top: -20px;
    }
  }

  .description_mobile {
    display: block;
    @media (min-width: 769px) {
      display: none;
    }

    margin-top: 40px;
    font-weight: 400;
    color: #fff;

    @media (min-width: 425px) {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    @media (min-width: 426px) and (max-width: 768px) {
      margin-top: 100px;
      margin-bottom: 90px;
    }
  }

  .description_desktop {
    display: none;
    @media (min-width: 769px) {
      display: block;
      font-weight: 500;
    }

    @media (max-width: 820px) {
      right: 0;
      font-size: 18px;
      max-width: 400px;
    }

    @media (min-width: 821px) {
      font-size: 18px;
      max-width: 400px;
    }

    @media (min-width: 1096px) {
      font-size: 22px;
      max-width: 600px;
    }
  }

  div.logos__wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;

    @media (max-width: 768px) {
      max-width: 230px;
      margin-top: 24px;
      justify-content: end;
      align-self: end;
    }

    @media (min-width: 426px) and (max-width: 768px) {
      max-width: 100%;
    }

    @media (min-width: 769px) {
      margin-top: 32px;
    }
    

    img {
      @media (max-width: 768px) {
        width: 70px;
      }

      @media (min-width: 426px) and (max-width: 768px) {
        width: 100px;
      }

      @media (min-width: 769px) {
        width: 55px;
      }

      @media (min-width: 821px) {
        width: 70px;
      }

      @media (min-width: 1096px) {
        width: 100px;
      }
    }
  }

  button {
    margin-top: 32px;
    border-radius: 8px;

    @media (max-width: 320px) {
      padding: 4px 8px;
      font-size: 12px;
      margin-top: 30px;
      border-radius: 8px;
      align-self: flex-end;
      color: #fff;
    }

    @media (min-width: 321px) {
      padding: 4px 8px;
      font-size: 12px;
      margin-top: 60px;
      border-radius: 8px;
      align-self: flex-end;
      color: #fff;
    }

    @media (min-width: 426px) and (max-width: 768px) {
      font-size: 22px;
      padding: 8px 16px;
      margin-top: 330px;
    }

    @media (min-width: 769px) {
      margin-top: 24px;
      padding: 8px 16px;
      font-size: 22px;
      align-self: center;
    }
  }
`
